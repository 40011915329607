<script>
  import { Route, Router } from 'svelte-routing'
  import { SvelteToast } from '@zerodevx/svelte-toast'

  import Menu from './components/Menu.svelte'
  import { API_URL, getCookie } from './helpers/apiCall'
  import AddAudio from './pages/add-audio.svelte'
  import AdminApiDoc from './pages/admin/dev/api-doc.svelte'
  import AdminAutomaticPost from './pages/admin/dev/automatic-post.svelte'
  import AdminCli from './pages/admin/dev/cli.svelte'
  import AdminCountPage from './pages/admin/dev/count.svelte'
  import AdminDevScripts from './pages/admin/dev/dev-scripts.svelte'
  import AdminImportData from './pages/admin/dev/import-data.svelte'
  import AdminWordForms from './pages/admin/dev/word-forms.svelte'
  import AdminWordInfoDev from './pages/admin/dev/word-info.svelte'
  import Rules from './pages/admin/grammar/rules.svelte'
  import RulesOrder from './pages/admin/grammar/rules-order.svelte'
  import WifsGrammar from './pages/admin/grammar/wifs.svelte'
  import ManageEnglishLessons from './pages/admin/lessons/manage-english-lessons.svelte'
  import CrudPages from './pages/admin/mix/crud-pages.svelte'
  import EntryDetails from './pages/admin/mix/entry-details.svelte'
  import AdminSummaryUsers from './pages/admin/summary/users.svelte'
  import TranslateWords from './pages/admin/translate-words.svelte'
  import UnclearMeaningSentences from './pages/admin/unclear-meaning-sentences.svelte'
  import AssignWif from './pages/admin/wif-assign.svelte'
  import CheckWif from './pages/admin/wif-check.svelte'
  import AdminWordInfo from './pages/admin/word-info.svelte'
  import Docs from './pages/docs.svelte'
  import Home from './pages/home.svelte'
  import CreateReadingTutorial from './pages/ipa/create-reading-tutorial.svelte'
  import LanguagePage from './pages/language.svelte'
  import LanguageAdd from './pages/language/add.svelte'
  import ApproveTranslation from './pages/lesson/approve-translation.svelte'
  import PosTagging from './pages/lesson/pos-tagging.svelte'
  import Translate from './pages/lesson/translate.svelte'
  import Login from './pages/login.svelte'
  import AddAudioSentence from './pages/mix/add-audio-sentence.svelte'
  import AddAudioWord from './pages/mix/add-audio-word.svelte'
  import ModerateReports from './pages/mix/moderate-reports.svelte'
  import TasksDashboard from './pages/mix/tasks-dashboard.svelte'
  import ResetPage from './pages/reset.svelte'
  import WordGroups from './pages/word-groups.svelte'
  import WordProps from './pages/word-props.svelte'
  import AddWordExample from './pages/words/add-word-examples.svelte'
  import TranslateWord from './pages/words/translate-word.svelte'

  export let url = ''
  let logged

  const jwt = getCookie('jwt')
  fetch(`${API_URL}/auth/validateToken`, {
    body: JSON.stringify({ jwt, path: window.location.pathname }),
    method: 'POST'
  })
    .then((result) => {
      if (result.status === 200) {
        result.json().then(() => {
          logged = true
        // navigate('/', { replace: true })
        })
      } else {
        logged = false
      }
    })
    .catch((e) => {
      console.error(e.message)
    })
</script>
<SvelteToast />
{#if typeof logged !== 'undefined'}
  <main>
    <Router {url}>
      <Menu {logged} />
      <div class='main-content'>
        {#if logged}
          <!-- Not language related -->
          <Route component={AdminCli} path='/admin/dev/cli/:iso' />
          <Route component={AdminCli} path='/admin/dev/cli' />
          <Route component={AdminAutomaticPost} path='/admin/dev/automatic-post' />
          <Route component={AdminImportData} path='/admin/dev/import-data' />
          <Route component={AdminApiDoc} path='/admin/dev/api-doc' />
          <Route component={AdminCountPage} path='/admin/dev/count' />
          <Route component={AdminWordInfoDev} path='/admin/dev/word-info-dev' />
          <Route component={AdminWordInfo} path='/admin/word-info' />
          <Route component={AdminWordForms} path='/admin/dev/word-forms' />
          <Route component={AdminDevScripts} path='/admin/dev/dev-scripts' />
          <Route component={AdminSummaryUsers} path='/admin/summary/users' />

          <Route component={Docs} path='/docs/:page' />
          <Route component={Docs} path='/docs/' />

          <Route component={UnclearMeaningSentences} path='/admin/unclear-meaning-sentences' />
          <Route component={EntryDetails} path='entry/:type/:id' />
          <Route component={CrudPages} path='/crud/:type' />
          <Route component={CrudPages} path=':iso/crud/:type' />
          <Route component={CrudPages} path='/crud' />
          <Route component={CrudPages} path=':iso/crud' />
          <Route component={LanguageAdd} path='/language/add' />
          <Route component={ModerateReports} path='/mix/moderate-reports' />
          <!-- Admin -->
          <Route component={TasksDashboard} path='/tasks-dashboard' />
          <Route component={AdminWordForms} path=':iso/admin/dev/word-forms' />
          <Route component={RulesOrder} path=':iso/admin/grammar/rules-order' />
          <Route component={Rules} path=':iso/admin/grammar/rules/:ruleId' />
          <Route component={Rules} path=':iso/admin/grammar/rules' />
          <Route component={WifsGrammar} path=':iso/admin/grammar/wifs' />

          <Route component={ManageEnglishLessons} path=':iso/admin/lessons/manage-english-lessons' />
          <Route component={AssignWif} path=':iso/admin/wif-assign' />
          <Route component={CheckWif} path=':iso/admin/wif-check' />

          <Route component={AdminWordInfo} path=':iso/admin/dev/word-info' />

          <Route component={TranslateWords} path=':iso/admin/translate-words' />

          <Route component={CreateReadingTutorial} path=':iso/ipa/create-reading-tutorial' />
          <Route component={ApproveTranslation} path=':iso/lesson/approve-translation' />
          <Route component={PosTagging} path=':iso/lesson/pos-tagging' />
          <Route component={PosTagging} path=':iso/lesson/pos-tagging/:lessonId' />
          <Route component={Translate} path=':iso/lesson/translate' />
          <Route component={AddAudioSentence} path=':iso/mix/add-audio-sentence/:level' />
          <Route component={AddAudioSentence} path=':iso/mix/add-audio-sentence' />
          <Route component={AddAudioWord} path=':iso/mix/add-audio-word/:level' />
          <Route component={AddAudioWord} path=':iso/mix/add-audio-word' />
          <Route component={AddWordExample} path=':iso/words/add-word-examples' />
          <Route component={AddWordExample} path=':iso/words/add-word-examples-all' />
          <Route component={TranslateWord} path=':iso/words/translate-word' />
          <Route component={WordProps} path='word-props/:group' />
          <Route component={WordProps} path='word-props/:group/:page' />
          <Route component={WordProps} path=':iso/word-props/:group' />
          <Route component={WordProps} path=':iso/word-props/:group/:page' />
          <Route component={WordGroups} path='word-groups' />
          <Route component={WordGroups} path=':iso/word-groups' />
          <Route component={AddAudio} path='add-audio/:type' />

          <Route component={LanguagePage} path=':iso' />
          <Route path='/reset'>
            <ResetPage />
          </Route>
        {:else}
          <Route component={Login} path='/login' />
        {/if}
        <Route path='/'>
          {#if !!logged}
            <Home />
          {/if}
        </Route>
      </div>
    </Router>
  </main>
{:else}
  <p>Loading...</p>
{/if}
<style lang='scss'>
  .main-content {
    margin-top: 0.1rem;
    padding: 0 1.5rem;
    background: var(--light-gray-background);

    @media (max-width: 768px) {
      background: var(--white-background);
    }
  }
</style>
